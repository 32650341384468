import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel';
import { WebUrl, ApiUrl, ApiKey, AlertTypes } from '../../../util/Constant';
import * as Util from '../../../util/Util';
import { showMessage } from "../../../redux/AppAction";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import NavigationButton from '../../../components/custom/NavigationButton';
import ApiEngine from '../../../util/ApiEngine.js';
import Select from 'react-select';
import Dropzone from 'react-dropzone';
import ReactTable from 'components/custom/ReactTable';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

/// <summary>
/// Author: CK
/// </summary>
const VendorDetail = props => {
    let _history = useHistory();
    let _location = useLocation();
    let _dispatch = useDispatch();
    const { register, handleSubmit, errors, setValue, watch } = useForm();
    const {
        register: registerLobby,
        handleSubmit: handleSubmitLobby,
        errors: errorsLobby
    } = useForm();
    const { t, i18n } = useTranslation();
    const [selectedVendor, setSelectedVendor] = useState(null);
    const [rewardEnable, setRewardEnable] = useState(false);
    const [useProxy, setUseProxy] = useState(false);
    const [checkStrikeEnabled, setCheckStrikeEnabled] = useState(false);
    const [autoSetStrike, setAutoSetStrike] = useState(false);
    const [manualRealDataEnabled, setManualReadDataEnabled] = useState(false);
    const [fixAmountSetScore, setFixAmountSetScore] = useState(false);
    const [enableRollover, setEnableRollover] = useState(false);
    const [isAutoRollover, setIsAutoRollover] = useState(true);
    const [useAgentLogForRollover, setUseAgentLogForRollover] = useState(false);
    const [useAgentGameLogForRollover, setUseAgentGameLogForRollover] = useState(false);
    const [rolloverTypes, setRolloverTypes] = useState([]);
    const [selectedRollover, setSelectedRollover] = useState(1);
    const [logoFile, setLogoFile] = useState();
    const [logoImage, setLogoImage] = useState();
    const [isAutoLogin, setIsAutoLogin] = useState(false);
    const [isHavingApp, setIsHavingApp] = useState(false);
    const [hasLobby, setHasLobby] = useState(false);
    const [lobbyBannerFile, setLobbyBannerFile] = useState();
    const [lobbyBanner, setLobbyBanner] = useState();
    const [lobbyGameUrl, setLobbyGameUrl] = useState();
    const [lobbyGameDetail, setLobbyGameDetail] = useState({});
    const [lobbyGameImageFile, setLobbyGameImageFile] = useState();
    const [lobbyGameImage, setLobbyGameImage] = useState();
    const [gameListFile, setGameListFile] = useState(null);
    const [enableAutoFetchGame, setEnableAutoFetchGame] = useState(false);
    const [inMaintenance, setInMaintenance] = useState(false);
    const [addMForLoginUrl, setAddMForLoginUrl] = useState(false);

    let _lobbyTableColumns = useMemo(() => [
        {
            Header: "IMAGE",
            accessor: "image",
            Cell: ({ row }) => {
                return <div><img class="mini-program-logo" src={row.original.image} /></div>
            },
            disableSortBy: true
        },
        {
            Header: "CATEGORY",
            accessor: "category"
        },
        {
            Header: "NAME",
            accessor: "name",
            width: 400,
        },
        {
            Header: "CODE",
            accessor: "code"
        },
        {
            Header: "PRIORITY",
            accessor: "priority"
        },
        {
            Header: "STATUS",
            Cell: ({ row }) => {
                return <div className="switcher">
                    <input type="checkbox"
                        ref={register}
                        name={`${row.original.id}_status`}
                        id={`${row.original.id}_status`}
                        defaultChecked={row.original.isActive}
                        onChange={(e) => { updateLobbyGameStatus(row.original.id, e.target.checked, row.original.vendorId); }}
                    />
                    <label htmlFor={`${row.original.id}_status`}></label>
                </div>
            },
        },
        {
            Header: "ACTION",
            Cell: ({ row }) => {
                return <span>
                    <button type='button' className="btn btn-primary" onClick={() => { getLobbyGameDetail(row.original.id); }}>{t("EDIT")}</button>
                </span>
            },
        }
    ], [lobbyGameUrl]);

    /// <summary>
    /// Author: CK
    /// </summary>
    useEffect(() => {
        if (_location.state && _location.state.id) {
            getVendorDetails(_location.state.id);
        }

        init();
    }, []);

    /// <summary>
    /// Author: CK
    /// </summary>
    useEffect(() => {
        if (selectedVendor && Util.stringIsNullOrEmpty(lobbyGameUrl)) {
            setLobbyGameUrl(`${ApiUrl._API_LOBBY_GAME}?vendorId=${selectedVendor.id}&v=${new Date()}`);
        }
    }, [selectedVendor]);

    /// <summary>
    /// Author: YJ
    /// </summary>
    async function init() {
        let responseJson = await ApiEngine.get(ApiUrl._API_GET_VENDOR_ROLLOVER_TYPES);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let tempType = [];
            responseJson[ApiKey._API_DATA_KEY].map((type, index) => {
                tempType.push({ label: type.name, value: type.id });
            })

            setRolloverTypes(tempType);
        }
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function updateLobbyGameStatus(lobbyId, status, vendorId) {
        let responseJson = await ApiEngine.put(ApiUrl._API_LOBBY_GAME + '/' + lobbyId, Util.createMultiPartFormBody({ vendorId: vendorId, isActive: status }));

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => {
                if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setValue(`${lobbyId}_status`, !status);
                }
            },
        }));
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    async function getLobbyGameDetail(id) {
        try {
            var responseJson = await ApiEngine.get(ApiUrl._API_LOBBY_GAME + '/' + id);

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            setLobbyGameDetail(responseJson[ApiKey._API_DATA_KEY]);
            setLobbyGameImage(responseJson[ApiKey._API_DATA_KEY].image);
        }
        catch (err) {
            _dispatch(showMessage({
                type: AlertTypes._ERROR,
                content: t(err),
            }));
        }
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    async function getVendorDetails(id) {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_VENDOR_BY_ID + "?id=" + id);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setSelectedVendor(responseJson[ApiKey._API_DATA_KEY]);
            setRewardEnable(responseJson[ApiKey._API_DATA_KEY].enableRewardCalculation);
            setUseProxy(responseJson[ApiKey._API_DATA_KEY].useProxy);
            setCheckStrikeEnabled(responseJson[ApiKey._API_DATA_KEY].enableStrikeCheck);
            setAutoSetStrike(responseJson[ApiKey._API_DATA_KEY].autoSetStrike);
            setManualReadDataEnabled(responseJson[ApiKey._API_DATA_KEY].enableManualReadScore);
            setFixAmountSetScore(responseJson[ApiKey._API_DATA_KEY].fixAmountSetScore);
            setEnableRollover(responseJson[ApiKey._API_DATA_KEY].enableRolloverCalculation);
            setIsAutoRollover(responseJson[ApiKey._API_DATA_KEY].isAutoRollover);
            setUseAgentLogForRollover(responseJson[ApiKey._API_DATA_KEY].useAgentLogForRollover);
            setUseAgentGameLogForRollover(responseJson[ApiKey._API_DATA_KEY].useAgentGameLogForRollover);
            setSelectedRollover(responseJson[ApiKey._API_DATA_KEY].vendorRolloverTypeId);
            setLogoImage(responseJson[ApiKey._API_DATA_KEY].imagePath);
            setIsAutoLogin(responseJson[ApiKey._API_DATA_KEY].isAutoLogin);
            setIsHavingApp(responseJson[ApiKey._API_DATA_KEY].isHavingApp);
            setHasLobby(responseJson[ApiKey._API_DATA_KEY].hasLobby)
            setLobbyBanner(responseJson[ApiKey._API_DATA_KEY].lobbyBanner);
            setEnableAutoFetchGame(responseJson[ApiKey._API_DATA_KEY].enableAutoFetchGame);
            setInMaintenance(responseJson[ApiKey._API_DATA_KEY].inMaintenance);
            setAddMForLoginUrl(responseJson[ApiKey._API_DATA_KEY].addMForLoginUrl);
        }
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    async function onSubmit(data) {
        if (selectedVendor) {
            data['id'] = selectedVendor.id;
        }

        data['enableRewardCalculation'] = rewardEnable;
        data['useProxy'] = useProxy;
        data['enableStrikeCheck'] = checkStrikeEnabled;
        data['autoSetStrike'] = autoSetStrike;
        data['enableManualReadScore'] = manualRealDataEnabled;
        data['vendorRolloverTypeId'] = selectedRollover;
        data['enableRolloverCalculation'] = enableRollover;
        data['isAutoRollover'] = isAutoRollover;
        data['useAgentLogForRollover'] = useAgentLogForRollover;
        data['useAgentGameLogForRollover'] = useAgentGameLogForRollover;
        data['isAutoLogin'] = isAutoLogin;
        data['isHavingApp'] = isHavingApp;
        data['hasLobby'] = hasLobby;
        data['enableAutoFetchGame'] = enableAutoFetchGame;
        data['inMaintenance'] = inMaintenance;
        data['addMForLoginUrl'] = addMForLoginUrl;

        if (logoFile) {
            data['imagePath'] = logoFile;
        }

        if (lobbyBannerFile) {
            data['lobbyBanner'] = lobbyBannerFile;
        }

        var responseJson = await ApiEngine.post(ApiUrl._API_CREATE_OR_UPDATE_VENDOR, Util.createMultiPartFormBody(data));

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    if (hasLobby) {
                        getVendorDetails(responseJson[ApiKey._API_DATA_KEY]['id']);
                    }
                    else {
                        Util.navigateTo(_history, WebUrl._URL_ADMIN_MANAGE_VENDOR);
                    }
                }
            },
        }));
    }

    /// <summary>
    /// Author : Wind
    /// </summary>
    const imageDrop = (acceptedFiles) => {
        if (acceptedFiles.length !== 0) {
            const file = acceptedFiles[0];
            setLogoFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setLogoImage(reader.result);
            }
            reader.readAsDataURL(file);
        }
    }

    /// <summary>
    /// Author : Wind
    /// </summary>
    const lobbyBannerDrop = (acceptedFiles) => {
        if (acceptedFiles.length !== 0) {
            const file = acceptedFiles[0];
            setLobbyBannerFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setLobbyBanner(reader.result);
            }
            reader.readAsDataURL(file);
        }
    }

    /// <summary>
    /// Author : Saitama
    /// </summary>
    const lobbyGameImageDrop = (acceptedFiles) => {
        if (acceptedFiles.length !== 0) {
            const file = acceptedFiles[0];
            setLobbyGameImageFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setLobbyGameImage(reader.result);
            }
            reader.readAsDataURL(file);
        }
    }

    /// <summary>
    /// Author : Wind
    /// </summary>
    async function fetchGameList() {
        try {
            if (selectedVendor) {
                var fetchGameResponse = await ApiEngine.post(ApiUrl._API_FETCH_LOBBY_GAME_LIST.replace(':vendorId', selectedVendor.id));

                if (!fetchGameResponse[ApiKey._API_SUCCESS_KEY]) {
                    throw fetchGameResponse[ApiKey._API_MESSAGE_KEY];
                }

                setLobbyGameUrl(`${ApiUrl._API_LOBBY_GAME}?vendorId=${selectedVendor.id}&v=${new Date()}`);
            }
        }
        catch (err) {
            _dispatch(showMessage({
                type: AlertTypes._ERROR,
                content: t(err)
            }));
        }
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    async function onSaveLobbyDetail(data) {
        data['vendorId'] = lobbyGameDetail.vendorId;

        if (lobbyGameImageFile) {
            data['image'] = lobbyGameImageFile;
        }

        var responseJson = await ApiEngine.put(ApiUrl._API_LOBBY_GAME + '/' + lobbyGameDetail.id, Util.createMultiPartFormBody(data));

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setLobbyGameUrl(`${ApiUrl._API_LOBBY_GAME}?vendorId=${selectedVendor.id}&v=${new Date()}`);
                    setLobbyGameDetail({});
                }
            },
        }));
    }

    /// <summary>
    /// Author: CK
    /// </summary>
    const handleFileChange = (event) => {
        setGameListFile(event.target.files[0]);
    };

    /// <summary>
    /// Author: CK
    /// </summary>
    const uploadGameListFile = async () => {
        let param = {
            VendorId: selectedVendor.id,
            JsonFile: gameListFile
        };

        var responseJson = await ApiEngine.post(ApiUrl._API_UPLOAD_GAME_LIST_FILE, Util.createMultiPartFormBody(param));

        _dispatch(showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setLobbyGameUrl(`${ApiUrl._API_LOBBY_GAME}?vendorId=${selectedVendor.id}&v=${new Date()}`);
                }
            },
        }));

        setGameListFile(null);
    };

    return (
        <div id="page-content">
            <h1 className="page-header">{selectedVendor ? t("EDIT_VENDOR_DETAILS") : t("CREATE_VENDOR")}
                <NavigationButton history={_history} />
            </h1>
            <Panel>
                <PanelHeader noButton>
                    {t("VENDOR_DETAILS")}
                </PanelHeader>
                <PanelBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("LOGO")}</label>
                                    <Dropzone accept={'image/*'} onDrop={acceptedFiles => imageDrop(acceptedFiles)}>
                                        {({ getRootProps, getInputProps }) => (
                                            <section>
                                                <div className="dropzone" style={{ minHeight: "200px", textAlign: "center" }} {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    {Util.stringIsNullOrEmpty(logoImage) && <h4 style={{ color: "grey" }} >{t("UPLOAD_LOGO")}</h4>}
                                                    {!Util.stringIsNullOrEmpty(logoImage) && <aside className="thumbsContainer">
                                                        <div className="thumb">
                                                            <div className="thumbInner">
                                                                <img
                                                                    src={logoImage}
                                                                    className="dropzone-img"
                                                                />
                                                            </div>
                                                        </div>
                                                    </aside>}
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                    {
                                        !Util.stringIsNullOrEmpty(logoImage) &&
                                        <button type="button" className="mt-2 btn btn-danger"
                                            onClick={() => {
                                                setLogoImage("");
                                                setLogoFile();
                                            }}>
                                            {t("REMOVE_IMAGE")}
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("GAME_NAME")}</label>
                                    <input
                                        className="form-control form-control-lg"
                                        type="text"
                                        id="gameName"
                                        name="gameName"
                                        defaultValue={selectedVendor ? selectedVendor.gameName : ""}
                                        placeholder={t("ENTER_GAME_NAME")}
                                        ref={register({ required: "PLEASE_ENTER_GAME_NAME" })} />
                                    {errors["gameName"] && <div className="invalid-feedback">{t(errors["gameName"].message)}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("NAME")}</label>
                                    <input
                                        className="form-control form-control-lg"
                                        type="text"
                                        id="name"
                                        name="name"
                                        defaultValue={selectedVendor ? selectedVendor.name : ""}
                                        placeholder={t("ENTER_NAME")}
                                        ref={register({ required: "PLEASE_ENTER_NAME" })} />
                                    {errors["name"] && <div className="invalid-feedback">{t(errors["name"].message)}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("SHORT_NAME")}</label>
                                    <input
                                        className="form-control form-control-lg"
                                        type="text"
                                        id="shortName"
                                        name="shortName"
                                        defaultValue={selectedVendor ? selectedVendor.shortName : ""}
                                        placeholder={t("ENTER_SHORT_NAME")}
                                        ref={register({ required: "PLEASE_ENTER_SHORT_NAME" })} />
                                    {errors["shortName"] && <div className="invalid-feedback">{t(errors["shortName"].message)}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("API_BASE_URL")}</label>
                                    <input
                                        className="form-control form-control-lg"
                                        type="text"
                                        id="baseUrl"
                                        name="baseUrl"
                                        defaultValue={selectedVendor ? selectedVendor.baseUrl : ""}
                                        placeholder={t("ENTER_BASE_URL")}
                                        ref={register({ required: "PLEASE_ENTER_BASE_URL" })} />
                                    {errors["baseUrl"] && <div className="invalid-feedback">{t(errors["baseUrl"].message)}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("CODE")}</label>
                                    <input
                                        className="form-control form-control-lg"
                                        type="text"
                                        id="code"
                                        name="code"
                                        defaultValue={selectedVendor ? selectedVendor.code : ""}
                                        placeholder={t("ENTER_CODE")}
                                        ref={register({ required: "PLEASE_ENTER_CODE" })} />
                                    {errors["code"] && <div className="invalid-feedback">{t(errors["code"].message)}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("DESCRIPTION")}</label>
                                    <input
                                        className="form-control form-control-lg"
                                        type="text"
                                        id="description"
                                        name="description"
                                        defaultValue={selectedVendor ? selectedVendor.description : ""}
                                        placeholder={t("ENTER_DESCRIPTION")}
                                        ref={register} />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("APP_DEEP_LINK")}</label>
                                    <input
                                        className="form-control form-control-lg"
                                        type="text"
                                        id="appDeepLink"
                                        name="appDeepLink"
                                        defaultValue={selectedVendor ? selectedVendor.appDeepLink : ""}
                                        placeholder={t("ENTER_APP_DEEP_LINK")}
                                        ref={register} />
                                    <small class="f-s-12 text-black-lighter">Tags: Player ID = <span class="badge badge-light badge-square p-5 f-s-12">{"{playerid}"}</span>,
                                        Game Password = <span class="badge badge-light badge-square p-5 f-s-12">{"{gamepassword}"}</span></small>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("APP_PACKAGE_NAME")}</label>
                                    <input
                                        className="form-control form-control-lg"
                                        type="text"
                                        id="appPackageName"
                                        name="appPackageName"
                                        defaultValue={selectedVendor ? selectedVendor.appPackageName : ""}
                                        placeholder={t("ENTER_PACKAGE_NAME")}
                                        ref={register} />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("APP_DOWNLOAD_URL_ANDROID")}</label>
                                    <input
                                        className="form-control form-control-lg"
                                        type="text"
                                        id="appDownloadUrlAndroid"
                                        name="appDownloadUrlAndroid"
                                        defaultValue={selectedVendor ? selectedVendor.appDownloadUrlAndroid : ""}
                                        placeholder={t("ENTER_APP_DOWNLOAD_URL_ANDROID")}
                                        ref={register} />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("APP_DOWNLOAD_URL_IOS")}</label>
                                    <input
                                        className="form-control form-control-lg"
                                        type="text"
                                        id="appDownloadUrlIos"
                                        name="appDownloadUrlIos"
                                        defaultValue={selectedVendor ? selectedVendor.appDownloadUrlIos : ""}
                                        placeholder={t("ENTER_APP_DOWNLOAD_URL_IOS")}
                                        ref={register} />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label>{t("RANKING")}</label>
                                    <input
                                        className="form-control form-control-lg"
                                        type="number"
                                        id="ranking"
                                        name="ranking"
                                        defaultValue={selectedVendor ? selectedVendor.ranking : ""}
                                        placeholder={t("ENTER_RANKING")}
                                        ref={register({
                                            required: "PLEASE_ENTER_VALID_RANK",
                                            min: {
                                                value: 0,
                                                message: 'PLEASE_ENTER_VALID_RANK'
                                            }
                                        })} />
                                    {errors["ranking"] && <div className="invalid-feedback">{t(errors["ranking"].message)}</div>}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label><b>{t("ROLLOVER_TYPE")}</b></label>
                                    <Select name="selectedRollover" options={rolloverTypes}
                                        placeholder={(rolloverTypes.filter(option => option.value == selectedRollover)[0] !== undefined) ? (rolloverTypes.filter(option => option.value == selectedRollover)[0].label) : ""}
                                        value={rolloverTypes.filter(option => option.value == selectedRollover)}
                                        onChange={(e) => {
                                            setSelectedRollover(e.value);
                                        }} />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("ENABLE_REWARD_CALCULATION")}</label><br />
                                    <div className="switcher">
                                        <input type="checkbox" name="rewardEnable" id="rewardEnable"
                                            onChange={(e) => {
                                                setRewardEnable(e.target.checked)
                                            }}
                                            value={true}
                                            checked={rewardEnable}
                                            ref={register} />
                                        <label htmlFor="rewardEnable"></label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("ENABLE_MANUAL_READ_SCORE")}</label><br />
                                    <div className="switcher">
                                        <input type="checkbox" name="manualRealDataEnabled" id="manualRealDataEnabled"
                                            onChange={(e) => {
                                                setManualReadDataEnabled(e.target.checked)
                                            }}
                                            value={true}
                                            checked={manualRealDataEnabled}
                                            ref={register} />
                                        <label htmlFor="manualRealDataEnabled"></label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("FIX_AMOUNT_SET_SCORE")}</label><br />
                                    <div className="switcher">
                                        <input type="checkbox" name="fixAmountSetScore" id="fixAmountSetScore"
                                            onChange={(e) => {
                                                setFixAmountSetScore(e.target.checked)
                                            }}
                                            value={true}
                                            checked={fixAmountSetScore}
                                            ref={register} />
                                        <label htmlFor="fixAmountSetScore"></label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("ENABLE_ROLLOVER_CALCULATION")}</label><br />
                                    <div className="switcher">
                                        <input type="checkbox" name="enableRollover" id="enableRollover"
                                            onChange={(e) => {
                                                setEnableRollover(e.target.checked)
                                            }}
                                            value={false}
                                            checked={enableRollover}
                                            ref={register} />
                                        <label htmlFor="enableRollover"></label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("USE_AGENT_LOG_FOR_ROLLOVER")}</label><br />
                                    <div className="switcher">
                                        <input type="checkbox" name="useAgentLogForRollover" id="useAgentLogForRollover"
                                            onChange={(e) => {
                                                setUseAgentLogForRollover(e.target.checked)
                                            }}
                                            value={false}
                                            checked={useAgentLogForRollover}
                                            ref={register} />
                                        <label htmlFor="useAgentLogForRollover"></label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("USE_AGENT_GAME_LOG_FOR_ROLLOVER")}</label><br />
                                    <div className="switcher">
                                        <input type="checkbox" name="useAgentGameLogForRollover" id="useAgentGameLogForRollover"
                                            onChange={(e) => {
                                                setUseAgentGameLogForRollover(e.target.checked)
                                            }}
                                            value={false}
                                            checked={useAgentGameLogForRollover}
                                            ref={register} />
                                        <label htmlFor="useAgentGameLogForRollover"></label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("IS_AUTO_ROLLOVER")}</label><br />
                                    <div className="switcher">
                                        <input type="checkbox" name="isAutoRollover" id="isAutoRollover"
                                            onChange={(e) => {
                                                setIsAutoRollover(e.target.checked)
                                            }}
                                            value={true}
                                            checked={isAutoRollover}
                                            ref={register} />
                                        <label htmlFor="isAutoRollover"></label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("USE_PROXY")}</label><br />
                                    <div className="switcher">
                                        <input type="checkbox" name="useProxy" id="useProxy"
                                            onChange={(e) => {
                                                setUseProxy(e.target.checked)
                                            }}
                                            value={true}
                                            checked={useProxy}
                                            ref={register} />
                                        <label htmlFor="useProxy"></label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("ENABLE_STRIKE_CHECK")}</label><br />
                                    <div className="switcher">
                                        <input type="checkbox" name="checkStrikeEnable" id="checkStrikeEnable"
                                            onChange={(e) => {
                                                setCheckStrikeEnabled(e.target.checked)
                                            }}
                                            value={true}
                                            checked={checkStrikeEnabled}
                                            ref={register} />
                                        <label htmlFor="checkStrikeEnable"></label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("AUTO_SET_STRIKE")}</label><br />
                                    <div className="switcher">
                                        <input type="checkbox" name="autoSetStrike" id="autoSetStrike"
                                            onChange={(e) => {
                                                setAutoSetStrike(e.target.checked)
                                            }}
                                            value={true}
                                            checked={autoSetStrike}
                                            ref={register} />
                                        <label htmlFor="autoSetStrike"></label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("IS_AUTO_LOGIN")}</label><br />
                                    <div className="switcher">
                                        <input type="checkbox" name="isAutoLogin" id="isAutoLogin"
                                            onChange={(e) => {
                                                setIsAutoLogin(e.target.checked)
                                            }}
                                            value={true}
                                            checked={isAutoLogin}
                                            ref={register} />
                                        <label htmlFor="isAutoLogin"></label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("IS_HAVING_APP")}</label><br />
                                    <div className="switcher">
                                        <input type="checkbox" name="isHavingApp" id="isHavingApp"
                                            onChange={(e) => {
                                                setIsHavingApp(e.target.checked)
                                            }}
                                            value={true}
                                            checked={isHavingApp}
                                            ref={register} />
                                        <label htmlFor="isHavingApp"></label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("HAS_LOBBY")}</label><br />
                                    <div className="switcher">
                                        <input type="checkbox" name="hasLobby" id="hasLobby"
                                            onChange={(e) => {
                                                setHasLobby(e.target.checked)
                                            }}
                                            value={true}
                                            checked={hasLobby}
                                            ref={register} />
                                        <label htmlFor="hasLobby"></label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("ENABLE_AUTO_GAME_FETCH")}</label><br />
                                    <div className="switcher">
                                        <input type="checkbox" name="enableAutoFetchGame" id="enableAutoFetchGame"
                                            onChange={(e) => {
                                                setEnableAutoFetchGame(e.target.checked)
                                            }}
                                            value={false}
                                            checked={enableAutoFetchGame}
                                            ref={register} />
                                        <label htmlFor="enableAutoFetchGame"></label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("IN_MAINTENANCE")}</label><br />
                                    <div className="switcher">
                                        <input type="checkbox" name="inMaintenance" id="inMaintenance"
                                            onChange={(e) => {
                                                setInMaintenance(e.target.checked)
                                            }}
                                            value={false}
                                            checked={inMaintenance}
                                            ref={register} />
                                        <label htmlFor="inMaintenance"></label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label>{t("ADD_M_FOR_LOGIN_URL")}</label><br />
                                    <div className="switcher">
                                        <input type="checkbox" name="addMForLoginUrl" id="addMForLoginUrl"
                                            onChange={(e) => {
                                                setAddMForLoginUrl(e.target.checked)
                                            }}
                                            value={false}
                                            checked={addMForLoginUrl}
                                            ref={register} />
                                        <label htmlFor="addMForLoginUrl"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            checkStrikeEnabled &&
                            <div className="row">
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label>{t("HIGH_PAYOUT_RATE")} (%)</label>
                                        <input
                                            className="form-control form-control-lg"
                                            type="number"
                                            id="payoutRate"
                                            name="payoutRate"
                                            step={'any'}
                                            defaultValue={selectedVendor ? selectedVendor.payoutRate : 0}
                                            placeholder={t("ENTER_HIGH_PAYOUT_RATE")}
                                            ref={register({
                                                required: "PLEASE_ENTER_VALID_HIGH_PAYOUT_RATE",
                                                min: {
                                                    value: 0,
                                                    message: 'PLEASE_ENTER_VALID_HIGH_PAYOUT_RATE'
                                                }
                                            })} />
                                        {errors["payoutRate"] && <div className="invalid-feedback">{t(errors["payoutRate"].message)}</div>}
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label>{t("HIGH_BONUS_RATE")} (%)</label>
                                        <input
                                            className="form-control form-control-lg"
                                            type="number"
                                            id="bonusRate"
                                            name="bonusRate"
                                            step={'any'}
                                            defaultValue={selectedVendor ? selectedVendor.bonusRate : 0}
                                            placeholder={t("ENTER_HIGH_BONUS_RATE")}
                                            ref={register({
                                                required: "PLEASE_ENTER_VALID_HIGH_BONUS_RATE",
                                                min: {
                                                    value: 0,
                                                    message: 'PLEASE_ENTER_VALID_HIGH_BONUS_RATE'
                                                }
                                            })} />
                                        {errors["bonusRate"] && <div className="invalid-feedback">{t(errors["bonusRate"].message)}</div>}
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label>{t("DOWNLINE_COMMISSION_RATE")} (%)</label>
                                        <input
                                            className="form-control form-control-lg"
                                            type="number"
                                            id="downlineCommissionRate"
                                            name="downlineCommissionRate"
                                            step={'any'}
                                            defaultValue={selectedVendor ? selectedVendor.downlineCommissionRate : 0}
                                            placeholder={t("ENTER_DOWNLINE_COMMISSION_RATE")}
                                            ref={register({
                                                required: "PLEASE_ENTER_VALID_DOWNLINE_COMMISSION_RATE",
                                                min: {
                                                    value: 0,
                                                    message: 'PLEASE_ENTER_VALID_DOWNLINE_COMMISSION_RATE'
                                                }
                                            })} />
                                        {errors["downlineCommissionRate"] && <div className="invalid-feedback">{t(errors["downlineCommissionRate"].message)}</div>}
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            hasLobby &&
                            <div className="row">
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label>{t("LOBBY_BANNER")}</label>
                                        <Dropzone accept={'image/*'} onDrop={acceptedFiles => lobbyBannerDrop(acceptedFiles)}>
                                            {({ getRootProps, getInputProps }) => (
                                                <section>
                                                    <div className="dropzone" style={{ minHeight: "200px", textAlign: "center" }} {...getRootProps()}>
                                                        <input {...getInputProps()} />
                                                        {Util.stringIsNullOrEmpty(lobbyBanner) && <h4 style={{ color: "grey" }} >{t("UPLOAD_LOBBY_BANNER")}</h4>}
                                                        {!Util.stringIsNullOrEmpty(lobbyBanner) && <aside className="thumbsContainer">
                                                            <div className="thumb">
                                                                <div className="thumbInner">
                                                                    <img
                                                                        src={lobbyBanner}
                                                                        className="dropzone-img"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </aside>}
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                        {
                                            !Util.stringIsNullOrEmpty(lobbyBanner) &&
                                            <button type="button" className="mt-2 btn btn-danger"
                                                onClick={() => {
                                                    setLobbyBanner("");
                                                    setLobbyBannerFile();
                                                }}>
                                                {t("REMOVE_IMAGE")}
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            hasLobby && selectedVendor?.id && !Util.stringIsNullOrEmpty(lobbyGameUrl) &&
                            <div className='row'>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label>{t("GAME_LIST")}</label>
                                        <button type="button" className="ml-3 btn-success m-r-10"
                                            onClick={() => { fetchGameList(); }}>
                                            <i class="fas fa-sync"></i>
                                        </button>
                                        <div>
                                            <input type="file" onChange={handleFileChange} accept="application/json,.json" />
                                            <br />
                                            {
                                                gameListFile &&
                                                <button type="button" className="m-t-10 btn btn-primary"
                                                    onClick={() => {
                                                        _dispatch(showMessage({
                                                            type: AlertTypes._WARNING,
                                                            showCancel: true,
                                                            content: "Confirm to upload game list?",
                                                            onConfirm: () => {
                                                                uploadGameListFile();
                                                            },
                                                        }));
                                                    }}>
                                                    Upload Game List JSON File
                                                </button>
                                            }
                                        </div>
                                        <ReactTable fetchUrl={lobbyGameUrl} columns={_lobbyTableColumns} globalFilterable={false} />
                                    </div>
                                </div>
                            </div>
                        }
                        <hr />
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <button type="submit" className="btn btn-primary">{selectedVendor ? t("BTN_SAVE") : t("CREATE")}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </PanelBody>
            </Panel>
            <Modal isOpen={!Util.isObjectEmpty(lobbyGameDetail)} toggle={() => { setLobbyGameDetail({}) }} size="lg">
                <form onSubmit={handleSubmitLobby(onSaveLobbyDetail)}>
                    <ModalHeader toggle={() => { setLobbyGameDetail({}) }}><h4 className='mb-0'>
                        {t("EDIT_LOBBY_GAME")}
                    </h4>
                    </ModalHeader>
                    <ModalBody>
                        <div className="form-group col-lg-3">
                            <label>{t("IMAGE")}</label>
                            <Dropzone accept={'image/*'} onDrop={acceptedFiles => lobbyGameImageDrop(acceptedFiles)}>
                                {({ getRootProps, getInputProps }) => (
                                    <section>
                                        <div className="dropzone" style={{ minHeight: "200px", textAlign: "center" }} {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            {Util.stringIsNullOrEmpty(lobbyGameImage) && <h4 style={{ color: "grey" }} >{t("UPLOAD_LOGO")}</h4>}
                                            {!Util.stringIsNullOrEmpty(lobbyGameImage) && <aside className="thumbsContainer">
                                                <div className="thumb">
                                                    <div className="thumbInner">
                                                        <img
                                                            src={lobbyGameImage}
                                                            className="dropzone-img"
                                                        />
                                                    </div>
                                                </div>
                                            </aside>}
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                            {
                                !Util.stringIsNullOrEmpty(lobbyGameImage) &&
                                <button type="button" className="mt-2 btn btn-danger"
                                    onClick={() => {
                                        setLobbyGameImage("");
                                        setLobbyGameImageFile();
                                    }}>
                                    {t("REMOVE_IMAGE")}
                                </button>
                            }
                        </div>
                        <div className="form-group">
                            <label>{t("NAME")}</label>
                            <input
                                className="form-control form-control-lg"
                                id="name"
                                name="name"
                                type="text"
                                placeholder={t("ENTER_NAME")}
                                defaultValue={lobbyGameDetail?.name}
                                ref={registerLobby({ required: "PLEASE_ENTER_NAME" })} />
                            {errorsLobby.name && <div className="invalid-feedback">{t(errorsLobby.name.message)}</div>}
                        </div>
                        <div className="form-group">
                            <label>{t("CODE")}</label>
                            <input
                                className="form-control form-control-lg"
                                id="code"
                                name="code"
                                type="text"
                                placeholder={t("ENTER_CODE")}
                                defaultValue={lobbyGameDetail?.code}
                                ref={registerLobby({ required: "PLEASE_ENTER_CODE" })} />
                            {errorsLobby.code && <div className="invalid-feedback">{t(errorsLobby.code.message)}</div>}
                        </div>
                        <div className="form-group">
                            <label>{t("PRIORITY")}</label>
                            <input
                                className="form-control form-control-lg"
                                id="priority"
                                name="priority"
                                type="number"
                                placeholder={t("ENTER_PRIORITY")}
                                defaultValue={lobbyGameDetail?.priority}
                                ref={registerLobby({ required: "PLEASE_ENTER_PRIORITY" })} />
                            {errorsLobby.priority && <div className="invalid-feedback">{t(errorsLobby.priority.message)}</div>}
                        </div>
                        <div className="form-group">
                            <label>{t("CATEGORY")}</label>
                            <input
                                className="form-control form-control-lg"
                                id="category"
                                name="category"
                                type="text"
                                placeholder={t("ENTER_CATEGORY")}
                                defaultValue={lobbyGameDetail?.category}
                                ref={registerLobby({ required: "PLEASE_ENTER_CATEGORY" })} />
                            {errorsLobby.category && <div className="invalid-feedback">{t(errorsLobby.category.message)}</div>}
                        </div>
                        <div className="form-group">
                            <label>{t("STATUS")}</label><br />
                            <div className="switcher">
                                <input type="checkbox"
                                    ref={registerLobby}
                                    name='isActive'
                                    id='isActive'
                                    defaultChecked={lobbyGameDetail?.isActive}
                                />
                                <label htmlFor='isActive'></label>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button type="submit" className="btn btn-primary btn-action">{t("BTN_SUBMIT")}</button>
                    </ModalFooter>
                </form>
            </Modal>
        </div>
    );
}

export default VendorDetail;